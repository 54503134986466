import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const api = {
	_getList: (isProd ? '' : '/api') + '/newAdmin.php?c=Criticismsuggestion&a=getList',
    _reply: (isProd ? '' : '/api') + '/newAdmin.php?c=Criticismsuggestion&a=changeStatus',
    _getComplainList: (isProd ? '' : '/api') + '/newAdmin.php?c=Prosecution&a=getList',
    _complainReply: (isProd ? '' : '/api') + '/newAdmin.php?c=Prosecution&a=changeStatus',
}


export function _getList(parameter) {
	return request({
		url: api._getList,
		method: 'post',
		data: parameter
	})
}


export function _reply(parameter) {
	return request({
		url: api._reply,
		method: 'post',
		data: parameter
	})
}

export function _getComplainList(parameter) {
	return request({
		url: api._getComplainList,
		method: 'post',
		data: parameter
	})
}

export function _complainReply(parameter) {
	return request({
		url: api._complainReply,
		method: 'post',
		data: parameter
	})
}

