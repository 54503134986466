<template>
  <page-header-wrapper>
    <template slot="extra">
      
    </template>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper" v-if="$auth('suggestionList.query')">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="20" :sm="24">
              <div style="display:flex">
                <a-form-item label="状态">
                  <a-select v-model="queryParam.status" style="width: 100px">
                        <a-select-option :value="0">
                          全部状态
                        </a-select-option>
                        <a-select-option :value="1">
                          未受理
                        </a-select-option>
                        <a-select-option :value="2">
                          已受理
                        </a-select-option>
                    </a-select>
                </a-form-item>
                
                <a-form-item label="时间筛选：" style="margin-left:5px;">
                    <a-range-picker @change="getDate" v-model="queryParam.time" style="width:260px"/>
                </a-form-item>
                
                <!-- <a-form-item label="关键词"  style="margin-left:5px;">
                  <a-input style="width: 180px" placeholder="请输入关键词搜索" v-model="queryParam.keyword" />
                </a-form-item> -->
              </div>
            </a-col>
            <a-col :md="4 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}">
                <a-button type="primary" @click="query" style="margin-left:5px;">查询</a-button>
                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-table ref="table" size="default" :rowKey="record => record.id" :columns="columns" :data-source="listData" :pagination="false"
        :scroll="{ x: '100%' }">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <span slot="source" slot-scope="data_source">
          <a-tag :color="getSource(data_source).color">{{ getSource(data_source).name }}</a-tag>
        </span>
        <span slot="state" slot-scope="record">
          <a-tag color="grey" v-if="record.status==1">未受理</a-tag>
          <a-tag color="green" v-else-if="record.status==2">已受理</a-tag>
        </span>
        <span slot="context" slot-scope="record">
            <ellipsis :length="50" tooltip class="ellip">{{ record.context }}</ellipsis>
          </span>
          <span slot="material" slot-scope="record">
            <span style="color:#0091ff;cursor:pointer;">[查看]</span>
          </span>
          <span slot="infos" slot-scope="record">{{ record.area }}</span>
          <span slot="material" slot-scope="record">
            <span v-if="record.material&&getArray(record.material).length>0" class="images" v-viewer>
                <img :src="prefix+data" alt="" v-for="data in getArray(record.material)" :key="data" style="width:28px;height: 28px;margin-right:5px;cursor:pointer;">
            </span>
            <span v-else>-</span>    
          </span>      
          <span slot="infos" slot-scope="record">
            <a-popover title="反映人信息详情">
                <template slot="content">
                    <div class="infos">
                        <div class="infos-items"><span>姓名:</span>{{ record.report_name||'-' }}</div>
                        <div class="infos-items"><span>身份证号:</span>{{ record.report_cardNum||'-' }}</div>
                        <div class="infos-items"><span>联系方式:</span>{{ record.report_phone||'-' }}</div>
                        <div class="infos-items"><span>政治面貌:</span>{{ record.report_political_outlook_name||'-' }}</div>
                        <div class="infos-items"><span>现居住地址:</span>{{ record.report_address||'-' }}</div>
                    </div>  
                </template>
                <span style="color:#0091ff;cursor:pointer;">[查看]</span>
            </a-popover>
          </span>
        <span slot="create_time" slot-scope="record">{{ record.create_time|time }}</span>
        
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)" v-if="$auth('suggestionList.reply')&&record.status==1" style="margin-right:10px;">回复</a>
            <a @click="showInfo(record)" v-if="$auth('suggestionList.reply')" style="margin-right:10px;">详情</a>
            <template v-if="record.status==2"> 
              <a-popover title="回复内容">
                  <template slot="content">
                    <p>{{record.reply_info}}</p>
                  </template>
                  <a-button type="primary" size="small">查看回复</a-button>
                </a-popover>
            </template>
          </template>
        </span>
      </a-table>
      
      <div style="text-align:right;margin-top:10px;">
        <a-pagination v-model="queryParam.page" :pageSize="queryParam.size" :total="total" show-less-items @change="change"/>
      </div>

      <!-- 发布 -->
      <a-modal class="modal_foot_center" v-model="modalVisible" title="回复" centered
        @ok="closeModal(1)" @cancel="closeModal(0)">
        <div>
          <div class="item" style="align-items: flex-start;">
            <div class="item_label">
              <i>*</i>
              回复内容
            </div>
            <div class="item_main">
              <a-textarea :rows="6" placeholder="请输入回复内容" v-model="form.reply_info" />
            </div>
          </div>
        </div>
      </a-modal>
      <!-- 详情 -->
      <a-drawer
            title="详情"
            placement="right"
            :closable="false"
            :visible="showDrawer"
            @close="onClose" width="400px"
          >
            <div v-if="info">
              <!-- <div class="info_list">
                <div class="info_list_lable">数据来源:</div>
                <div class="info_list_main">
                  <a-tag :color="getSource(info.data_source).color">{{ getSource(info.data_source).name }}</a-tag>
                </div>
              </div> -->
              <div class="info_list">
                <div class="info_list_lable">状态:</div>
                <div class="info_list_main">
                  <a-tag color="grey" v-if="info.status==1">未受理</a-tag>
                  <a-tag color="green" v-else-if="info.status==2">已受理</a-tag>
                </div>
              </div>
                <div class="info_list">
                  <div class="info_list_lable">主要内容:</div>
                  <div class="info_list_main">{{info.context||'-'}}</div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">附件:</div>
                  <div class="info_list_main">
                    <span v-if="info.material&&getArray(info.material).length>0" v-viewer>
                        <img :src="prefix+data" alt="" v-for="data in getArray(info.material)" :key="data" style="width:24px;height: 24px;margin-right:5px;cursor:pointer;">
                    </span>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">举报人姓名:</div>
                  <div class="info_list_main">{{info.report_name||'-'}}</div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">举报人身份证号:</div>
                  <div class="info_list_main">{{info.report_cardNum||'-'}}</div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">举报人联系方式:</div>
                  <div class="info_list_main">{{info.report_phone||'-'}}</div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">举报人政治面貌:</div>
                  <div class="info_list_main">{{info.report_political_outlook_name||'-'}}</div>
                </div>
                <div class="info_list">
                  <div class="info_list_lable">举报人现居住地址:</div>
                  <div class="info_list_main">{{info.report_address||'-'}}</div>
                </div>
              <div class="info_list">
                <div class="info_list_lable">提交时间:</div>
                <div class="info_list_main" v-if='info.create_time>0'>{{info.create_time|time}}</div>
                <div class="info_list_main" v-else>-</div>
              </div>
            </div>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
  import { getSource,prefix } from '@/utils'
  import moment from 'moment';
  import {
    Modal,
    message
  } from 'ant-design-vue';
  import {
    STable,
    Ellipsis
  } from '@/components';
  import {
    _getList,
    _reply
  } from '@/api/supervise';

  const columns = [{
      title: '序号',
      scopedSlots: {
        customRender: 'serial'
      },
      align: 'center',
      width: '60px'
    },
    // {
    // 	title: '数据来源',
    // 	dataIndex:'data_source',
    // 	scopedSlots: {
    // 		customRender: 'source'
    // 	},
    // 	align:'center',
    // 	width:'100px'
    // },
    {
      title: '状态',
      dataIndex: '',
      scopedSlots: {
        customRender: 'state'
      },
      align: 'center',
      width: '80px'
    },
    {
        title: '主要内容',
        dataIndex: '',
        scopedSlots: {
          customRender: 'context'
        },
        align: 'center',
        width: '150px'
      },
      {
        title: '附件',
        dataIndex: '',
        scopedSlots: {
          customRender: 'material'
        },
        align: 'center',
        width: '80px'
      },
      {
        title: '举报人信息详情',
        dataIndex: '',
        scopedSlots: {
          customRender: 'infos'
        },
        align: 'center',
        width: '120px'
      },
      {
        title: '提交时间',
        dataIndex: '',
        scopedSlots: {
          customRender: 'create_time'
        },
        align: 'center',
        width: '120px'
      },
    {
      title: '操作',
      dataIndex: 'action',
      width: '150px',
      scopedSlots: {
        customRender: 'action'
      },
      align: 'center'
    }
  ];

  const statusMap = {
    0: {
      status: 'default',
      text: '全部'
    },
    1: {
      status: 'default',
      text: '待审核'
    },
    2: {
      status: 'success',
      text: '审核通过'
    },
    3: {
      status: 'error',
      text: '审核拒绝'
    }
  };

  export default {
    name: '',
    components: {
      STable,
      Ellipsis
    },
    data() {
      this.columns = columns;
      return {
        prefix,
        visible: false,
        advanced: false,
        // 查询参数
        queryParam: {
          time: [],
          keyword: '',
          start_time: '',
          end_time: '',
          page:1,
          size:10,
          status:0,
        },
        listData:[],
        total:0,

        modalVisible: false, //发布弹窗
        url: '', //微信文章url

        title: '',
        img_url: '',
        link_url: '',

        form: {
          id: '',
          reply_info: '',
          status: 2
        },
        showDrawer:false,
        info:null,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getSource:getSource,
      getList(pageIndex){
        let params = this.queryParam;
        _getList(params).then(res => {
          if(res.code==200){
            this.listData = res.data.list;
            this.total = res.data.total;
          }
        })
      },
      change(e){
        //console.log(e);
        this.queryParam.page = e;
        this.getList(e);
      },
      showInfo(data){
        this.info = data;
        this.showDrawer = true;
      },
      onClose(){
        this.info = null;
        this.showDrawer = false;
      },
      add() {
        //新增
        this.modalVisible = true;
      },
      closeModal(status) {
        //回复弹窗关闭 1确定 2取消
        //console.log('status',status)
        let that = this;
        if(status==1){
          let params = {
            id: this.form.id,
            reply_info: this.form.reply_info,
            status: this.form.status
          };
          if (!this.form.reply_info) {
            this.$message.success('请输入回复内容');
            return false;
          } 
          _reply(params).then(res => {
            console.log(res);
            if (res.code == 200) {
              that.$message.success('回复成功' );
              that.getList(that.queryParam.page);
              that.modalVisible = false;
              that.form.id = '';
              that.form.reply_info = '';
            }
          });
        }else{
          this.modalVisible = false;
        }
        
      },
      getDate(date, dateString) {
        //时间筛选
        this.queryParam.time = dateString;
        this.queryParam.start_time = this.queryParam.time[0] ? this.queryParam.time[0]  : '';
        this.queryParam.end_time = this.queryParam.time[1] ? this.queryParam.time[1] : '';
      },
      handleEdit(data) {
        //编辑
        this.modalVisible = true;
        this.form.id = data.id;
        this.form.question = data.question;
        this.form.answer = data.answer;
      },
      query(){
        this.getList(1);
      },
      reset() {
        //重置
        this.queryParam.time = [];
        this.queryParam.start_time = '';
        this.queryParam.end_time = '';
        this.queryParam.keyword = '';
        this.queryParam.status=0;
        this.getList(1);
      },
      getArray(arr){
            let newArr = [];
            arr.map(item=>{
                if(item){
                    newArr.push(item);
                }
            })
            return newArr;
        }
    },
    filters: {
      time(t) {
        if (t) {
          return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss');
        } else {
          return t;
        }
      },
      statusFilter(type) {
        return statusMap[type].text;
      },
      statusTypeFilter(type) {
        return statusMap[type].status;
      }
    }
  };
</script>

<style scoped>
  .deliv_item {
    display: flex;
    align-items: center;
    margin-top: 36px;
  }

  .deliv_item_label {
    width: 120px;
  }

  .deliv_item_label i {
    font-style: normal;
    color: #ff3b30;
  }

  .modal_item {
    display: flex;
    margin-top: 10px;
  }

  .modal_item div:nth-child(1) {
    min-width: 112px;
  }

  .modal_item div:nth-child(2) img {
    margin-top: 5px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .item_label {
    min-width: 100px;
  }

  .item_label i {
    font-style: normal;
    color: #ff3b30;
    margin-right: 3px;
  }

  .item_main {
    min-width: 1px;
    width: 100%;
  }

  .ellip {
    cursor: pointer;
  }
  .info_list{
    display: flex;
    align-items: center;
    padding:10px 0;
  }
  .info_list .info_list_lable{
    min-width:120px;
    width:120px;
  }
  .info_list .info_list_main{
    min-width:200px;
    width:100%;
  }
</style>
